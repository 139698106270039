<template>
  <div>
    <div class="row">
      <div class="col">
        <div>
          <div class="form-group">
            <input
              v-model="searchText"
              class="form-control form-control-lg"
              type="text"
              placeholder=".form-control-lg"/>
          </div>
          <button type="button" class="btn btn-primary" v-on:click="search">Buscar</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">

        <table class="table table-hover" v-if="listGenerateFavorite">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">User_id</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(favorite, index) in listGenerateFavorite" :key="favorite.id">
              <th scope="row"> {{ index+1 }}</th>
              <td>{{ favorite.user_id}}</td>
              <td>{{ favorite.user.name}}</td>
              <td>{{ favorite.user.email}}</td>
              <td>{{ favorite.amount}}</td>
              <td> </td>
              <td> </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
//import { mapState } from "vuex";
import axios from 'axios'


export default {
  components: {
  },
  data() {
    return {
      listGenerateFavorite: [],
      searchText: "",
      resSearch: [],
      viewSearch:false
      //    loading:true
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    getData() {
      const url = this.appDomainApi1 + "admin/generate/data"
      axios
        .get(url, 
        )
        .then((resp) => {
          this.listGenerateFavorite = resp.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    search() {
      const url = this.appDomainApi1 + "admin/import/question/search";
      axios
        .post(
          url,
          { search: this.searchText },
        )
        .then((resp) => {
          if (resp.data.code == 200) {
            this.resSearch = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.imgav {
  display: flex;
  align-content: center;
  justify-content: center;
}
.cAvatar {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}
.cImage {
  position: absolute;
  width: 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.loading {
  height: 6.8em;
}
.dc1:hover {
  color: white;
  background: #628cfb;
  transition-duration: 100ms;
  cursor: pointer;
}
.dc1 {
  border-radius: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #e8f0ff;
  margin: 5px;
}
.dDetalle:hover {
  color: white;
}
.dDetalle {
  padding-top: 10px;
}
.dDetalle > p {
  margin-top: 97px;
  /* margin: -15px; */
  margin-left: 6px;
  padding: 0px;
  /* border: 0px; */
  margin-top: -15px;
  font-size: 16px;
  color: #1d2b8d;
  line-height: 1.2;
}
.nutemas {
  color: black;
  margin-left: 16px;
  font-family: inherit;
  font-size: 50px;
}
</style>
