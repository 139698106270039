<template>
    <div>
    <TitleAdmin msg="Generate favorite" routerAdmin="Admin"/>
    <Generate />

    </div>
</template>
<script>
import TitleAdmin from '@/views/constants/titleAdmin.vue'
import Generate from '@/components/admin/generate/index.vue'
export default {
    components:{
        TitleAdmin,
        Generate
    }
}
</script>